.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.titlebar {
  /* position: fixed; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* Set the width of the container */
  height: 100vh; /* Set the height of the container */
}

.title {
  width: 90%;
  max-width: 90%;
  max-height: 80px;
  margin-top: 30px;
}

.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 10% 70% 20%;
  grid-template-columns: 5% 90% 5%;
  /* border: 1px solid red; */
}

.top-row {
  grid-row: 1;
  grid-column: 1 / 4;
  display: grid;

  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 100%;
}
.top-row > div {
  height: 100%;
  /* border-right: 1px solid red; */
}

.middle-row {
  grid-row: 2;
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: 40% 40% 20%;
  grid-template-rows: 100%;
  padding: 20px;
  max-height: 400px;
  /* border: 1px solid red; */
}

.middle-row > div {
  /* border-right: 1px solid red; */
}

.bottom-row {
  grid-row: 3;
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: 30% 50% 20%;
  grid-template-rows: 100%;
  padding: 20px;
  /* border: 1px solid red; */
}

.graphic {
  width: 100%;
  max-height: 400px;
  /* background-color: #61dafb; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.linktextmail {
  font-family: "QuanticoRegular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #3170ce;
}

.linktextbold {
  font-family: "QuanticoBold";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #3170ce;
}
